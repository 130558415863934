import {FC} from 'react'

type CardBadgeProps = {
  text: string
  isAmount?: boolean
}
const CardBadge: FC<CardBadgeProps> = ({text, isAmount = false}) => {
  const colors = [
    {text: 'agriculture', color: '#FFFFFF', background: '#0C751D'},
    {text: 'food', color: '#FFFFFF', background: '#0011AA'},
    {text: 'completed', color: '#08B124', background: '#10FB3733'},
    {text: 'in progress', color: '#FAA707', background: '#FBAB1033'},
    {text: 'manufacturing', color: '#FFFFFF', background: '#AA0050'},

    {text: 'debt', color: '#5F1ED9', background: '#5F1ED933'},
    {text: 'retail', color: '#FFFFFF', background: '#0C751D'},
    {text: 'equity', color: '#D0021B', background: '#FFBDCB'},
    {text: 'grant', color: '#D3900D', background: '#FBAB1033'},
    {text: 'tourism', color: '#FFFFFF', background: '#0A2240'},
    {text: 'financial', color: '#FFFFFF', background: '#AA0050'},

    {text: 'tourism,retail and property', color: '#FFFFFF', background: '#0A2240'},
    {text: 'education', color: '#FFFFFF', background: '#181C32'},
    {text: 'legal', color: '#FFFFFF', background: '#0A2240'},
    {text: 'bronze', color: '#FFFFFF', background: '#816F0F'},
    {text: 'gold', color: '#FFFFFF', background: '#F4D323'},
    {text: 'silver', color: '#FFFFFF', background: '#7D7D81'},
    {text: 'grant', color: '#FFFFFF', background: '#AA0050'},
    {text: 'finance', color: '#FFFFFF', background: '#AA0050'},
  ]

  const defaultColorScheme = {color: '#FFFFFF', background: '#0A2240'}
  const amountColorScheme = {color: '#0011AA', background: '#1E96D933'}

  const colorScheme = isAmount
    ? amountColorScheme
    : colors.find((color) =>
    color.text.toLowerCase().includes(text.toLocaleLowerCase().substring(0, 3)),
  ) || defaultColorScheme

  return (
    <span
      className={`badge me-0 mb-0`}
      style={{
        backgroundColor: colorScheme.background,
        color: colorScheme.color,
      }}
    >
      {text}
    </span>
  )
}
export default CardBadge
