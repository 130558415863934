import {FC, useEffect, useRef, useState} from 'react'
import 'flatpickr/dist/flatpickr.min.css'
import {Formik, Form} from 'formik'
import {createFinancierSchema} from '../constants/CreateFinancierSchema'
import {Stepper, StepperComponent} from '../../shared/components/stepper'
import {ICreateFinancier} from '../interfaces'
import {CreateFinancierFormSteps} from '../../../../data/constants'
import {CreateContactInformation} from '../../shared/components/CreateContactInformation'
import {
  useAddNewFinancierMutation,
  useAddNewFinancierContactPersonMutation,
  useAddNewFinancierTypesMutation,
} from '../FinanciersApiSlice'
import {CreateFinancierFinancials} from '../modals'
import {Toolbar} from '../../../../_theme/layout/components/Toolbar'
import {useNavigate} from 'react-router'
import {useAddNewDocumentMutation} from '../../documents/DocumentsApiSlice'
import {ICreateDocument} from '../../documents'
import CreateFinancierGeneralInformation from '../components/CreateFinancierGeneralInformation'
import {useGetFinancingTypesQuery} from '../../companies/CompaniesApiSlice'
import {FetchBaseQueryError} from '@reduxjs/toolkit/query'

const CreateFinancier: FC = () => {
  const inits: ICreateFinancier = {
    generalInformation: {
      name: '',
      about: '',
      country: '',
      region: '',
      district: '',
      logo: '',
    },
    businessOperations: {
      dateOfIncorporation: '',
      sector: '',
      subSector: '',
      productLine: '',
    },
    financials: {
      financingTypes: [],
      maximumDebt: '0',
      maximumEquity: '0',
      maximumGrant: '0',
      currency: 'UGX',
      financierType: [],
    },
    contactPerson: {
      name: '',
      email: '',
      phoneNumber: '',
      address: '',
    },
  }
  const navigate = useNavigate()
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState<any>()
  const [initValues] = useState<ICreateFinancier>(inits)
  const [submitting, setSubmitting] = useState(false)
  const [countryCode, setCountryCode] = useState<string>('+256')
  const [addNewFinancier, {isLoading}] = useAddNewFinancierMutation()
  const [addFinancierTypes] = useAddNewFinancierTypesMutation()
  const [createDocument] = useAddNewDocumentMutation()
  const [addNewFinancierContactPerson] = useAddNewFinancierContactPersonMutation()
  const [fileObject, setFileObject] = useState<ICreateDocument | null>(null)
  const {data: financingTypesState} = useGetFinancingTypesQuery()

  const financingTypeSelection = Object.values(financingTypesState?.entities || {})
  const stepName: any = (step: number) => {
    const steps = ['generalInformation', 'financials', 'contactPerson', 'ReviewAndSubmit']
    return steps[step - 1]
  }

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)

    if (stepper.current) {
      const step = stepName(stepper.current.currentStepIndex)

      setCurrentSchema(createFinancierSchema.pick([step]))
    }
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    setSubmitting(false)

    const currentStepIndex = stepper.current.currentStepIndex

    if (currentStepIndex !== undefined && currentStepIndex > 0) {
      const step = stepName(currentStepIndex - 1)

      stepper.current.goPrev()

      setCurrentSchema(createFinancierSchema.pick([step]))
    }
  }

  const submitStep = async (values: ICreateFinancier) => {
    let financierTypesObjects: {financier_id: string; financing_type_id: string}[] = []
    if (!stepper.current) {
      return
    }
    const step = stepName(stepper.current.currentStepIndex + 1)

    setCurrentSchema(createFinancierSchema.pick([step]))

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      setSubmitting(true)

      let logoId = null

      try {
        if (fileObject) {
          const documents = [fileObject]
          const payload = {files: documents}
          const response = await createDocument(payload).unwrap()
          logoId = response[0].id
          setFileObject(null)
        }

        const newFinancier = await addNewFinancier({
          name: values.generalInformation.name,
          about: values.generalInformation.about,
          country: values.generalInformation.country,
          region: values.generalInformation.region,
          district: values.generalInformation.district,
          logo: logoId,
          equity_maximum_ticket_size: parseFloat(
            values?.financials?.maximumEquity?.replace(/,/g, '') || '0',
          ),
          debt_maximum_ticket_size: parseFloat(
            values?.financials?.maximumDebt?.replace(/,/g, '') || '0',
          ),
          grant_maximum_ticket_size: parseFloat(
            values?.financials?.maximumGrant?.replace(/,/g, '') || '0',
          ),
          email: '',
          countries: [],
          currency: values.financials.currency,
          financier_type: values?.financials?.financierType,
        }).unwrap()

        if (values.financials.financingTypes && values.financials.financingTypes.length > 0) {
          financierTypesObjects = values.financials.financingTypes.map((typeName) => {
            const type = financingTypeSelection.find((type) => type?.name === typeName)
            return {
              financier_id: newFinancier.id,
              financing_type_id: type ? type.id : '',
            }
          })
        }

        const financierTypePromises =
          financierTypesObjects.length > 0
            ? financierTypesObjects.map((financierType) =>
              addFinancierTypes(financierType).unwrap(),
            )
            : []

        await Promise.all([
          ...financierTypePromises,
          await addNewFinancierContactPerson({
            full_name: values.contactPerson.name,
            email: values.contactPerson.email,
            phone_number: `${countryCode}-${values.contactPerson.phoneNumber}`,
            address: values.contactPerson.address,
            financier: newFinancier.id,
          }).unwrap(),
        ])
        navigate('/financiers')
      } catch (error) {
        const errorCode = (error as FetchBaseQueryError)?.status?.toString()
        navigate(`/error/${errorCode}`)
      }
    }
  }
  const formRef = useRef<any>()

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <>
      <Toolbar page="Add a New Financier"></Toolbar>
      <div className="container-fluid p-0">
        <div className="card">
          <div
            ref={stepperRef}
            className="row stepper stepper-pills stepper-row"
            id="kt_modal_create_service_provider_stepper"
          >
            <Formik
              innerRef={formRef}
              validationSchema={currentSchema}
              initialValues={initValues}
              onSubmit={submitStep}
            >
              {({
                  values,
                  touched,
                  errors,
                  handleChange,
                  setFieldValue,
                  setFieldTouched,
                  isValid,
                  dirty,
                }) => (
                <Form className="form w-100" noValidate id="kt_modal_create_form">
                  <div className="card-body d-flex flex-column px-lg-20 py-lg-15">
                    <h2 className="f-s ps-xl-4 ps-xxl-7 ps-md-3">Create a Financier</h2>
                    <div className="stepper-nav d-flex h-100px py-lg-20 px-xl-20 px-xxl-20">
                      <Stepper items={CreateFinancierFormSteps} />
                    </div>
                    <div className="flex-column-fluid py-lg-8 px-xl-4 px-md-4 px-xxl-8">
                      {/* General Information */}

                      <CreateFinancierGeneralInformation
                        errors={errors}
                        touched={touched}
                        setFileObject={setFileObject}
                        setFieldValue={setFieldValue}
                      />

                      {/*Business Operations */}

                      <CreateFinancierFinancials
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />

                      {/* contact person */}
                      <CreateContactInformation
                        errors={errors}
                        touched={touched}
                        setCountryCode={setCountryCode}
                      />

                      {/* submission */}
                      <div data-kt-stepper-element="content">
                        <div className="w-100 text-center"></div>
                      </div>
                    </div>
                    <div className="row mb-10 pt-0 px-xl-4 px-md-4 px-xxl-8">
                      <div className="col-lg-6">
                        <button
                          type="button"
                          data-kt-stepper-action="previous"
                          onClick={prevStep}
                          className="btn btn-lg btn-outline btn-outline-secondary"
                          style={{
                            color:
                              stepper?.current?.currentStepIndex &&
                              stepper.current.currentStepIndex > 0
                                ? 'black'
                                : 'gray',
                          }}
                        >
                          Back
                        </button>
                      </div>
                      <div className="col-lg-6 text-md-end">
                        <button
                          type="submit"
                          className={`btn btn-lg ${
                            stepper?.current?.currentStepIndex ===
                            stepper?.current?.totatStepsNumber!
                              ? 'btn-danger'
                              : 'btn-outline btn-outline-secondary'
                          }`}
                          style={{
                            color:
                              stepper?.current?.currentStepIndex ===
                              stepper?.current?.totatStepsNumber!
                                ? 'white'
                                : isValid && dirty
                                  ? 'black'
                                  : 'gray',
                          }}
                        >
                          <span className="indicator-label">
                            {!submitting && (
                              <>
                                {stepper?.current?.currentStepIndex ===
                                stepper?.current?.totatStepsNumber!
                                  ? 'Submit'
                                  : 'Next'}
                              </>
                            )}
                            {submitting && (
                              <>
                                <span className="spinner-border h-15px w-15px align-middle text-white-400"></span>
                              </>
                            )}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  )
}
export {CreateFinancier}
