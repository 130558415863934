import {Field, FormikValues} from 'formik'
import {useGetFinancingTypesQuery} from '../../companies/CompaniesApiSlice'
import {currencies} from '../../shared/constants'
import NumberFormatter from '../../shared/components/NumberFormatter'

export const CreateFinancierFinancials = ({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            setFieldValue,
                                            setFieldTouched,
                                          }: FormikValues) => {
  const {data: financingTypesState} = useGetFinancingTypesQuery()

  const financingTypeSelection = Object.values(financingTypesState?.entities || {})

  const financierType = [
    {id: 'finance', name: 'Financier'},
    {id: 'grant', name: 'Grant Provider'},
  ]

  return (
    <div data-kt-stepper-element="content">
      <div className="w-100">
        <div className="fv-row row mb-4 p-0">
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-6">
              <span>Select Financing Types</span>
            </label>
            {financingTypeSelection.map((option) => (
              <label className="d-flex flex-stack cursor-pointer mb-5" key={option?.id}>
                <span className="d-flex align-items-center me-2">
                  <span className="form-check form-check-custom me-3">
                    <Field
                      className="form-check-input h-30px w-30px custom-checkbox"
                      type="checkbox"
                      name="financials.financingTypes"
                      value={option?.name}
                      checked={
                        (option && values.financials?.financingTypes?.includes(option?.name)) ||
                        false
                      }
                      onChange={(event: any) => {
                        handleChange(event)
                        setFieldValue(`maximum ${option?.name}`, '')
                        setFieldTouched(`maximum ${option?.name}`, false)
                      }}
                    />
                  </span>
                  <span className="d-flex flex-column">
                    <span className="fw-bolder fs-6">{option?.name}</span>
                  </span>
                </span>
              </label>
            ))}
          </div>
          <div className="col-lg-6">
            {values &&
              values.financials.financingTypes &&
              values.financials.financingTypes.includes('Debt') && (
                <>
                  <div className="fv-row mb-10 mt-10">
                    <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
                      <span>Maximum Ticket Size - Debt</span>
                    </label>
                    <div className="row">
                      <div className="col-3 pe-0 ">
                        <Field
                          as="select"
                          name="financials.currency"
                          className="form-select form-select-solid rounded-end-0 py-lg-5 ps-8"
                          multiple={false}
                        >
                          {Object.entries(currencies).map(([key, value]) => (
                            <option value={value}>{key}</option>
                          ))}
                        </Field>
                      </div>
                      <div className="col-9 ps-0 ">
                        <NumberFormatter
                          name="financials.maximumDebt"
                          className="form-control border-start-0 rounded-start-0 py-lg-5"
                          setFieldValue={setFieldValue}
                          initialValue={values.financials.maximumDebt || 0}
                        />
                        {touched?.financials?.maximumDebt && (
                          <div className="text-danger mt-2">{errors?.financials?.maximumDebt}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
        <div className="fv-row row mb-4 p-0">
          <div className="col-lg-6">
            {values &&
              values.financials.financingTypes &&
              values.financials.financingTypes.includes('Equity') && (
                <>
                  <div className="fv-row mb-10 mt-10">
                    <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
                      <span>Maximum Ticket Size - Equity</span>
                    </label>
                    <div className="row">
                      <div className="col-3 pe-0 ">
                        <Field
                          as="select"
                          name="financials.currency"
                          className="form-select form-select-solid rounded-end-0 py-lg-5 ps-8"
                          multiple={false}
                        >
                          {Object.entries(currencies).map(([key, value]) => (
                            <option value={value}>{key}</option>
                          ))}
                        </Field>
                      </div>
                      <div className="col-9 ps-0 ">
                        <NumberFormatter
                          name="financials.maximumEquity"
                          className="form-control border-start-0 rounded-start-0 py-lg-5"
                          setFieldValue={setFieldValue}
                          initialValue={values.financials.maximumEquity || 0}
                        />
                        {touched?.financials?.maximumEquity && (
                          <div className="text-danger mt-2">
                            {errors?.financials?.maximumEquity}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
          </div>
          <div className="col-lg-6">
            {values &&
              values.financials.financingTypes &&
              values.financials.financingTypes.includes('Grant') && (
                <>
                  <div className="fv-row mb-10 mt-10">
                    <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
                      <span>Maximum Ticket Size - Grant</span>
                    </label>
                    <div className="row">
                      <div className="col-3 pe-0 ">
                        <Field
                          as="select"
                          name="financials.currency"
                          className="form-select form-select-solid rounded-end-0 py-lg-5 ps-8"
                          multiple={false}
                        >
                          {Object.entries(currencies).map(([key, value]) => (
                            <option value={value}>{key}</option>
                          ))}
                        </Field>
                      </div>

                      <div className="col-9 ps-0 ">
                        <NumberFormatter
                          name="financials.maximumGrant"
                          className="form-control border-start-0 rounded-start-0 py-lg-5"
                          setFieldValue={setFieldValue}
                          initialValue={values.financials.maximumGrant || 0}
                        />

                        {touched?.financials?.maximumGrant && (
                          <div className="text-danger mt-2">{errors?.financials?.maximumGrant}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
          </div>
          <div className="fv-row row mb-4 p-0">
            <div className="col-lg-12">
              <label className="d-flex align-items-center fs-4 f-s fw-bold mb-6">
                <span>Select Financier Types</span>
              </label>

              {financierType.map((type) => (
                <label className="d-flex flex-stack cursor-pointer mb-5" key={type.id}>
                  <span className="d-flex align-items-center me-2">
                    <span className="form-check form-check-custom me-3">
                      <Field
                        className="form-check-input h-30px w-30px custom-checkbox"
                        type="checkbox"
                        name="financials.financierType"
                        value={type.id}
                        checked={values.financials?.financierType?.includes(type.id) || false}
                        onChange={(event: any) => {
                          const currentTypes = values.financials?.financierType || []
                          const newTypes = currentTypes.includes(type.id)
                            ? currentTypes.filter((t: string) => t !== type.id)
                            : [...currentTypes, type.id]
                          setFieldValue('financials.financierType', newTypes)
                          setFieldTouched('financials.financierType', true)
                        }}
                      />
                    </span>
                    <span className="d-flex flex-column">
                      <span className="fw-bolder fs-6">{type.name}</span>
                    </span>
                  </span>
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
