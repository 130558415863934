import React, {FC} from 'react'
import {useNavigate} from 'react-router-dom'
import {getInitials} from '../../../../utils/stringHelpers'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../data/redux/Store'
import {createFinancierSelectors} from '../FinanciersApiSlice'
import {useGetDocumentsQuery} from '../../documents/DocumentsApiSlice'
import {Col, Row} from 'react-bootstrap'
import CardBadge from '../../shared/components/CardBadge'
import CardDetail from '../../shared/components/CardDetail'
import {IListPageFilters} from '../../shared/interfaces/IListPageFilters'
import {ratings} from '../constants/Ratings'

type FinancierCardProps = {
  id: string
  page: number
  pageSize: number
  searchTerm: string
  filters: IListPageFilters
}

export const FinancierCard: FC<FinancierCardProps> = ({
                                                        id,
                                                        page,
                                                        pageSize,
                                                        searchTerm,
                                                        filters,
                                                      }) => {
  const navigate = useNavigate()
  const {selectById: selectFinancierById} = createFinancierSelectors({
    page,
    pageSize,
    searchTerm,
    ...filters,
  })
  const financier = useSelector((state: RootState) => selectFinancierById(state, id))
  const logoId = financier?.logo || null
  const {data: documentsState, isLoading: gettingLogo} = useGetDocumentsQuery(
    logoId ? [logoId] : [],
    {
      skip: !financier,
    },
  )
  const logo = financier ? documentsState?.entities[financier.logo] : null

  const financierInitials = getInitials(financier?.name, 3)
  const sizes = [
    Number(financier?.debtMaximumTicketSize),
    Number(financier?.grantMaximumTicketSize),
    Number(financier?.equityMaximumTicketSize),
  ].filter((size): size is number => !isNaN(size) && size > 0)
  sizes.sort((a, b) => a - b) // Sort sizes in ascending order

  const maximumTicketSize = sizes[sizes.length - 1] || 0
  const minimumTicketSize = sizes[0] || 0

  const handleNavigate = () => {
    navigate(`/financiers/${financier?.id}`, {
      state: {currentPage: page, pageSize},
    })
  }
  const getRatingLabel = (value: string | undefined): string => {
    const rating = ratings.find((r) => r.value === value)
    return rating ? rating.label : ''
  }

  const financingTypes = [
    (financier?.debtMaximumTicketSize ?? 0) > 0 ? 'Debt' : '',
    (financier?.grantMaximumTicketSize ?? 0) > 0 ? 'Grant' : '',
    (financier?.equityMaximumTicketSize ?? 0) > 0 ? 'Equity' : '',
  ].filter((type) => type)

  const financierTypes = financier?.financierType || []

  return (
    <div className="col-md-6 col-lg-4 col-sm-12">
      <div
        onClick={handleNavigate}
        className="card border border-2 border-gray-300 mb-5 d-flex flex-column justify-content-evenly min-height-160px"
      >
        <div className="px-5 py-2">
          <div className="d-flex justify-content-between align-items-center">
            <span
              className="fw-bolder text-dark fs-6 text-truncate"
              style={{
                maxWidth: '200%',
                display: 'block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {financier?.name}
            </span>
            {/*<Row className="mb-2 h-30px ps-7">*/}
            {/*  {financierTypes.map((type: string, index: number) => (*/}
            {/*    <Col key={index} className="mb-2 flex-grow-0 px-1">*/}
            {/*      <CardBadge key={index} text={type} />*/}
            {/*    </Col>*/}
            {/*  ))}*/}
            {/*</Row>*/}
          </div>
        </div>

        <Row className="px-5">
          <Col className="pe-2">
            <CardDetail
              title="Minimum Ticket Size"
              value={`${financier?.currency} ${minimumTicketSize.toLocaleString()}`}
            />
          </Col>
          <Col className="ps-2">
            <CardDetail
              title="Maximum Ticket Size"
              value={`${financier?.currency} ${maximumTicketSize.toLocaleString()}`}
            />
          </Col>
        </Row>

        <div className="px-5 d-flex justify-content-between align-items-center h-50px">
          <span className="fs-9 fw-bold text-gray-500 w-50">Location</span>
          <span className="text-gray-800 fs-7 w-50">
            <span className="fs-8 text-gray-800 fw-bolder w-50">
              {`${financier?.region ? `${financier.region}, ` : ''}${financier?.country}`}
            </span>
          </span>
        </div>
        <Row className="mb-2 h-30px ps-7">
          {financingTypes.map((type: string, index: number) => (
            <Col key={index} className="mb-2 flex-grow-0 px-1">
              <CardBadge key={index} text={type} />
            </Col>
          ))}
        </Row>

      </div>
    </div>
  )
}
