import React, { useState } from 'react'
import { Row, Col, Card, Spinner, Modal, Form, Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useDispatch } from 'react-redux'
import { useGetDashboardOverviewQuery, useGetApplicationStagesQuery, useFetchStagesQuery } from '../FinanciersApiSlice'
import MapDashboard from './MapDashboard'
import StatisticsSection from './StatisticCard'
import { toAbsoluteUrl } from '../../../../_theme/helpers'
import Charts from './Charts'
import { setCallOverviewFilters } from '../../../../data/redux/settingsReducer'

interface OverviewProps {
  id: string;
}

const Overview: React.FC<OverviewProps> = ({ id }) => {

  const dispatch = useDispatch()

  const [showModal, setShowModal] = useState(false)
  const [dateRangeOption, setDateRangeOption] = useState('last_month')
  // Temporary states for date selection in the modal
  const [tempStartDate, setTempStartDate] = useState<Date | null>(null)
  const [tempEndDate, setTempEndDate] = useState<Date | null>(null)

  const today = new Date()
  const thirtyDaysAgo = new Date()
  thirtyDaysAgo.setDate(today.getDate() - 30)
  const [startDate, setStartDate] = useState<Date | null>(thirtyDaysAgo)
  const [endDate, setEndDate] = useState<Date | null>(today)
  const [selectedOption, setSelectedOption] = useState("")
  const [selectedStageName, setSelectedStageName] = useState("")


  const { data, isFetching, error } = useGetDashboardOverviewQuery({
    id, searchParams: {
      startDate: startDate?.toISOString().split('T')[0],
      endDate: endDate?.toISOString().split('T')[0],
      stage: parseInt(selectedOption)
    },
  })

  const { data: stages, isLoading } = useFetchStagesQuery(id);

  const handleApply = () => {
    if (dateRangeOption === 'last_month') {
      const end = new Date()
      const start = new Date()
      start.setMonth(start.getMonth() - 1)
      setStartDate(start)
      setEndDate(end)
    } else if (dateRangeOption === 'last_6_months') {
      const end = new Date()
      const start = new Date()
      start.setMonth(start.getMonth() - 6)
      setStartDate(start)
      setEndDate(end)
    } else if (dateRangeOption === 'custom' && tempStartDate && tempEndDate) {
      setStartDate(tempStartDate)
      setEndDate(tempEndDate)
    }
    dispatch(
      setCallOverviewFilters({
        id,
        startDate: startDate?.toISOString().split('T')[0],
        endDate: endDate?.toISOString().split('T')[0],
      })
    )
    setShowModal(false)
  }

  const setStageSelected = (stage_id:string) => {
    setSelectedOption(stage_id)
    if(stage_id !== ""){
      const stageName = stages?.find((stage) => stage.id === parseInt(stage_id))
      if (stageName){
        setSelectedStageName(stageName.name)
      }
      dispatch(
        setCallOverviewFilters({
          id,
          startDate: startDate?.toISOString().split('T')[0],
          endDate: endDate?.toISOString().split('T')[0],
        })
      )
    }
  }


  if (isFetching || isLoading) {
    return (
      <div className="d-flex justify-content-center flex-column gap-3 align-items-center pt-5">
        <Spinner animation="border" variant="danger" />
        <p className="pt-0">Loading...</p>
      </div>
    )
  }
  return (
    <>
      <Row className="pt-2 px-6 mx-2">
        <div className="d-flex border-solid justify-content-between align-items-center">
          <div className="d-flex justify-content-start align-items-center">
            <span className="fw-bold">Summary</span>
            <button
              className="btn btn-sm ms-lg-3 d-flex align-items-center justify-content-center text-hover-danger btn-active-secondary h-35px br-40px
             bg-white text-danger"
            >
              <img
                src={toAbsoluteUrl(`/media/images/calendar3.svg`)}
                alt="#"
                className="h-15px w-12px"
                style={{ filter: 'invert(49%) sepia(73%) saturate(2738%) hue-rotate(321deg) brightness(96%) contrast(97%)' }}
              />
              <span className="ms-1">Last 30 days</span>
            </button>
            <button
              className="btn btn-sm ms-lg-3 d-flex align-items-center justify-content-center text-hover-danger btn-active-secondary h-35px br-40px
             bg-white text-danger"
            >
              {selectedOption !=="" ? <span>{selectedStageName}</span>:<span></span>}
            </button>
          </div>
          <div
            className="d-flex justify-content-center align-items-center"
          >
            <div className='px-2'>
              <select className='form-select' value={selectedOption} onChange={(e) => setStageSelected(e.target.value)}>
                <option value="">Select a stage</option>
                {stages?.map((stage) => (
                  <option key={stage.id} value={stage.id}>
                    {stage.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='badge-icon d-flex justify-content-center align-items-center' onClick={() => setShowModal(true)}>
              <i
                className="fa fa-filter text-danger" aria-hidden="true"></i>
            </div>

          </div>
        </div>
      </Row>

      {/* Modal for date selection */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title>Summary Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Period</Form.Label>
              <Form.Control
                as="select"
                value={dateRangeOption}
                onChange={(e) => setDateRangeOption(e.target.value)}
              >
                <option value="last_month">Last Month</option>
                <option value="last_6_months">Last 6 Months</option>
                <option value="custom">Custom Range</option>
              </Form.Control>
            </Form.Group>

            {/* Show date pickers only if 'Custom Range' is selected */}
            {dateRangeOption === 'custom' && (
              <div className='d-flex justify-content-between'>
                <Form.Group className="mt-3">
                  <Form.Label>Start Date</Form.Label>
                  <DatePicker
                    selected={tempStartDate}
                    maxDate={today}
                    onChange={(date: Date | null) => setTempStartDate(date ?? new Date())}
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                  />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label>End Date</Form.Label>
                  <DatePicker
                    selected={tempEndDate}
                    maxDate={today}
                    onChange={(date: Date | null) => setTempEndDate(date ?? new Date())}
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                  />
                </Form.Group>
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-between'>
          <Button variant="danger" onClick={handleApply}>
            Apply
          </Button>
          <Button variant="light" onClick={() => setShowModal(false)}>
            Clear
          </Button>
        </Modal.Footer>
      </Modal>

      <Row className="py-2 px-6 mx-2">
        <Col md={8}>
          <Row className="pe-0">
            <StatisticsSection genderData={data?.gender || []} applicantData={data?.applicant_total || []} />
          </Row>
          <Row className="pt-2">
            <Charts sectorData={data?.sector || []} applicationsPerWeek={data?.applicants_per_week || {}}
              ownerAgeRange={data?.owner_age || {}} yearsOfIncorporation={data?.year_of_incorporation || []} />
          </Row>
        </Col>
        <Col md={4} className="p-0">
          <Card style={{ width: '100%' }}>
            <Card.Body className="p-0">
              <MapDashboard countryData={data?.country || []} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Overview