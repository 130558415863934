import React from 'react'

interface StatisticCardProps {
  title: string;
  number: number |string;
}

const StatisticCard: React.FC<StatisticCardProps> = ({title, number}) => {
  return (
    <div className="d-flex flex-column align-items-start justify-content-start py-2 w-50 border-solid">
      <p className="fs-7 text-center">{title}</p>
      <h2 className="fs-1 fw-bold">{typeof number == "string"? number :number.toLocaleString()}</h2>
    </div>
  )
}

interface GenderDataItem {
  name: string;
  value: number;
}

interface StatisticsSectionProps {
  genderData: GenderDataItem[];
  applicantData: GenderDataItem[];
}


const StatisticsSection: React.FC<StatisticsSectionProps> = ({genderData, applicantData},
) => {
  const male = genderData.find((item) => item.name === 'male')?.value || 0
  const female = genderData.find((item) => item.name === 'female')?.value || 0
  const total = applicantData.find((item) => item.name === 'total')?.value || 0
  const statistics = [
    {title: 'Total No. of Applicants', number: total},
    {title: 'Percentage of Female owned', number: ""+female+"%"},
    {title: 'Percentage of Male owned', number: ""+male+"%"},
  ]

  return (
    <div className="d-flex justify-content-between gap-2 ps-0">
      {statistics.map((stat, index) => (
        <StatisticCard key={index} title={stat.title} number={stat.number} />
      ))}
    </div>
  )
}

export default StatisticsSection